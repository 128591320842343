import { useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import QRCode from 'qrcode';
import { Images } from '../../../../utils/images'
import {
  // maskCardNumber,
  // maskCardExpirationDate,
  maskCardCvv,
  // unMask,
  maskQuantityCads,
} from '../../../../utils/masks';
import MaskedInput from 'react-text-mask';
import { API_URL } from '../../../../services/api';
import Loading from '../../../../helpers/Loading';
import {
  Container,
  Result,
  RadioContainer,
  RadioContainerPayment,
  RadioBox,
  // InputsGrid,
  ValueContainer,
  IconButton,
  IconButtonContainer,
} from './styles';
import { env } from '../../../../env';
import { toCurrency } from '../../../../helpers/toCurrency';
import { RadioBoxCad } from '../../../shared/RadioBox';
// import { Profile } from '../../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faCreditCard, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Box, Dropdown } from 'rbx';
import { useFetch } from '../../../../hooks/useFetch';
import { Card } from '../CardModal/card';
import { ModalHeader } from '../../../shared/ModalHeader';
import { CardForm } from '../CardModal/form';

export function CadsModal({ isOpen, onRequestClose, options }) {
  const [quantity, setQuantity] = useState(10);
  const [paymentMethod, setPaymentMethod] = useState('pix');
  const [pixKey, setPixKey] = useState('');
  const [pixQR, setPixQR] = useState('');
  const [boletoUrl, setBoletoUrl] = useState('');
  const [boletoBarcode, setBoletoBarcode] = useState('');
  // const [cardNumber, setCardNumber] = useState('');
  // const [cardExpirationDate, setCardExpirationDate] = useState('');
  // const [cardHolderName, setCardHolderName] = useState('');
  const [cardCvv, setCardCvv] = useState('');
  const [requestSuccess, setRequestSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [minValue, setMinValue] = useState(1);
  const [cardSelected, setCardSelected] = useState({})
  const [activeDropdownCC, setActiveDropdownCC] = useState('')
  const [modal, setModal] = useState({})

  const profile = sessionStorage.getItem('profile')
  const discount = sessionStorage.getItem('discount')

  const { 
    response: cardsResponse,
    // load: cardsLoad,
    refresh: cardsRefresh
  } = useFetch(`${API_URL}/cards`)

  const cards = cardsResponse?.data?.cards || []

  const handleQuantity = (operation) => {
    if (operation === 'add' && quantity < 999) {
      setQuantity(+quantity + 1);
    }
    if (operation === 'remove' && quantity > minValue) {
      setQuantity(+quantity - 1);
    }
  };

  const handleBoleto = async (boletoId) => {
    const res = await fetch(`${API_URL}/transactions/${boletoId}`, {
      method: 'GET',
      cache: 'no-store',
      headers: {
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('token'),
      },
    });

    const response = await res.json();
    setBoletoUrl(response?.data?.boletoUrl);
    setBoletoBarcode(response?.data?.boletoBarcode);
  };

  const handleBuyCads = async (e) => {
    e.preventDefault();

    if (paymentMethod === '') {
      toast.error('Selecione uma forma de pagamento.');
      return;
    }
    if (+quantity < minValue) {
      if (paymentMethod === 'boleto') {
        toast.error(`Para Boleto a quantidade mínima é de ${minValue} Cartões.`)
      } else {
        toast.error(`A quantidade mínima é de ${minValue} Cartões.`)
      }
      return;
    }
    setLoading(true);

    const body = {
      quantity: +quantity,
      paymentMethod,
    }

    if (['credit_card','debit_card'].includes(paymentMethod)) {
      // body.cardExpirationDate = unMask(cardExpirationDate)

      // if (handleValidCard(body.cardExpirationDate) === false) {
      //   return;
      // }

      // body.cardNumber = unMask(cardNumber)
      // body.cardHolderName = cardHolderName
      body.cardId = cardSelected.pagarmeCardId
      body.cardCvv = cardCvv
    }

    const res = await fetch(`${API_URL}/transactions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: sessionStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    });

    const response = await res.json();
    let boletoId = response?.data?.boletoId;
    setPixKey(response?.data?.pixQrCode);

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setRequestSuccess(response.message);

      if (paymentMethod === 'boleto') {
        setTimeout(() => handleBoleto(boletoId), 2000);
      }

      if (paymentMethod === 'pix') {
        QRCode.toDataURL(response?.data?.pixQrCode).then((data) => {
          setPixQR(data);
        });
      }
    }
    setLoading(false);
  };

  const cleanState = () => {
    setQuantity(10)
    setPaymentMethod('pix')
    setPixKey('')
    setPixQR('')
    setBoletoUrl('')
    setBoletoBarcode('')
    // setCardNumber('')
    // setCardExpirationDate('')
    // setCardHolderName('')
    setCardCvv('')
    setRequestSuccess('')
    setMinValue(1)
    setCardSelected({})

    onRequestClose()
  }

  const RadioBoxContainer = ({ values=[] }) => {
    return (
      <RadioContainer>
        {values.map((cads, index) => (
          <RadioBoxCad key={index} cads={cads} isActive={quantity === cads} onClick={() => setQuantity(cads)} />
        ))}
      </RadioContainer>
    )
  }

  const RadioBoxPayment = ({ label, method, image, multiple }) => {
    return (
      <RadioBox
        type="button"
        onClick={() => {
          setPaymentMethod(method)
          setMinValue(method !== 'boleto' ? 1 : profile === 'user' ? 100 : 50)
          setQuantity(method !== 'boleto' ? 10 : profile === 'user' ? 100 : 50)
          setCardSelected(method !== 'credit_card' ? {} : cardSelected)
          setActiveDropdownCC(method === 'credit_card' ? 'is-active' : '')
        }}
        isActive={paymentMethod === method}
        activeColor="green"
      >
        <img src={image} alt={label} />
        <span>{label}</span>
        {
          multiple && <FontAwesomeIcon className='icon is-size-7' icon={faChevronDown} />
        }
      </RadioBox>
    )
  }

  const DropdownCreditCard = ({ cardsData, active, setActive }) => {
    return (
      <Dropdown className={active}>
        <Dropdown.Trigger>
          <RadioBoxPayment label='Crédito' method='credit_card' image={Images.creditCardImg} multiple />
        </Dropdown.Trigger>
        <Dropdown.Menu>
          <Dropdown.Content>
            <Dropdown.Item
              onClick={() => {
                setActive('')
                openCardForm()
              }}
            >
              <Box className='is-flex is-align-items-center'>
                <div
                  className='is-flex p-2 is-align-items-center ml-3 is-clickable'
                  style={{ borderRadius:'100%', backgroundColor:'var(--blue)' }}
                >
                  <FontAwesomeIcon icon={faPlus} style={{ color:'var(--shape)', fontSize:'0.7rem' }} />
                </div>
                <span className='ml-2 is-size-6' style={{ color:'var(--text-title)' }}>Novo Cartão de Crédito</span>
              </Box>
            </Dropdown.Item>
            <Dropdown.Divider />
            {cardsData.reverse().map((card, index) => (
              <Dropdown.Item
                key={'card_' + index}
                onClick={() => {
                  setCardSelected(card)
                  setActive('')
                }}
              >
                <Card card={card} />
              </Dropdown.Item>
            ))}
          </Dropdown.Content>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  const selectCard = (card, cardCvv) => {
    setCardSelected(card)
    setCardCvv(cardCvv)
  }

  const closeModal = () => setModal({})

  const openCardForm = () => {
    setModal({
      show: <CardForm
        isOpen={true}
        onRequestClose={closeModal}
        cardsRefresh={cardsRefresh}
        selectCard={selectCard}
      />
    })
  }

  if (options?.educationalPeriod?.valid && profile === 'user') {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={cleanState}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <ModalHeader title='Comprar Cartões' handleClose={cleanState} />

        <Result className="animeLeft">
          <h2>Periodo Educativo</h2>
          <p className="animeLeft resultCard">
            {options?.educationalPeriod?.message}
          </p>
          <img src={Images.trafficCopImg} alt="Sucesso" onClick={cleanState} />
        </Result>
      </Modal>
    );
  }

  if (requestSuccess !== '') {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={cleanState}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <ModalHeader title='Comprar Cartões' handleClose={cleanState} />

        {paymentMethod === 'pix' && (
          <Result className="animeLeft">
            <h2>
              Utilize esse código QR ou a chave pix copia e cola para realizar o
              pagamento:
            </h2>
            {/* <p className="animeLeft">{pixKey}</p> */}
            {pixQR && <img src={pixQR} alt="Pix Código QR" />}
            <div className="tooltip">
              <button
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(pixKey);
                  var tooltip = document.getElementById('myTooltip');
                  tooltip.innerHTML = 'Copiado!';
                }}
              >
                <span className="tooltiptext" id="myTooltip">
                  Copiar
                </span>
                Copiar chave pix
                <img src={Images.pixWhiteImg} alt="Pix" />
              </button>
            </div>
          </Result>
        )}

        {(['credit_card','debit_card'].includes(paymentMethod)) && (
          <Result className="animeLeft">
            <h2>{requestSuccess}</h2>
            <p className="animeLeft resultCard">
              Estamos processando sua transação. Seus cartões serão creditados
              em alguns segundos.
            </p>
            <img src={Images.successImg} alt="Sucesso" onClick={cleanState} className='is-clickable' />
          </Result>
        )}

        {paymentMethod === 'boleto' && (
          <Result className="animeLeft">
            <h2>
              Visualize o boleto abaixo ou copie o código de barras para
              realizar o pagamento:
            </h2>
            <p className="animeLeft">
              {boletoUrl ? (
                <a
                  href={boletoUrl}
                  target="_blank"
                  alt="Visualizar boleto"
                  rel="noopener noreferrer"
                >
                  {boletoUrl}
                </a>
              ) : (
                <Loading />
              )}
            </p>
            <div className="tooltip">
              <button
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(boletoBarcode);
                  var tooltip = document.getElementById('myTooltip');
                  tooltip.innerHTML = 'Copiado!';
                }}
              >
                <span className="tooltiptext" id="myTooltip">
                  Copiar
                </span>
                Copiar código boleto
                <img src={Images.barcodeWhiteImg} alt="Boleto" />
              </button>
            </div>
          </Result>
        )}
      </Modal>
    );
  }

  return (
    <>
      { modal.show }

      <Modal
        isOpen={isOpen}
        onRequestClose={cleanState}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <Container onSubmit={handleBuyCads}>
          <ModalHeader title='Comprar Cartões' handleClose={cleanState} />

          <h3>Forma de pagamento</h3>

          <RadioContainerPayment>
            <DropdownCreditCard cardsData={cards} active={activeDropdownCC} setActive={setActiveDropdownCC} />
            <RadioBoxPayment label='Pix' method='pix' image={Images.pixImg} />
            <RadioBoxPayment label='Boleto' method='boleto' image={Images.barcodeImg} />
          </RadioContainerPayment>

          {
            Object.keys(cardSelected).length > 0 && 
            <>
              <h4 className='mb-2'>
                <FontAwesomeIcon icon={faCreditCard} className='mr-2' />
                Cartão selecionado
              </h4>

              <div className='columns is-mobile my-1'>
                <div className='column pb-1'>
                  <Card card={cardSelected} />
                </div>
                <div className='column is-one-quarter pb-0 m-auto'>
                  <MaskedInput
                    mask={maskCardCvv}
                    placeholder="CVV"
                    guide={false}
                    type="text"
                    autoFocus
                    value={cardCvv}
                    onChange={(event) => setCardCvv(event.target.value)}
                    required
                  />
                </div>
              </div>

              <hr className='hr'></hr>
            </>
          }

          <h3 className='m-0'>
            Quantidade
          </h3>

          {
            paymentMethod === 'boleto'
              ? profile === 'user'
                ? <RadioBoxContainer values={[100, 125, 150]} />
                : <RadioBoxContainer values={[50, 75, 100]} />
              : <RadioBoxContainer values={[10, 20, 30]} />
          }

          <MaskedInput
            mask={maskQuantityCads}
            placeholder="Outra quantidade"
            guide={false}
            type="text"
            value={quantity}
            onChange={(event) => setQuantity(event.target.value)}
            required
          />
          <IconButtonContainer>
            <IconButton type="button" onClick={() => handleQuantity('remove')}>
              <img src={Images.removeBlueImg} alt="Menos" />
            </IconButton>

            <IconButton type="button" onClick={() => handleQuantity('add')}>
              <img src={Images.addBlueImg} alt="Mais" />
            </IconButton>
          </IconButtonContainer>

          <ValueContainer>
            {profile === 'pdv' ? (
              <>
                <h3>
                  Desconto:{' '}
                  <span style={{ color: '#33CC95' }}>
                    { toCurrency(+quantity * env.CAD_VALUE * (discount / 100)) }
                  </span>
                </h3>
                <h3>
                  Valor à pagar:{' '}
                  <span>
                    { toCurrency(+quantity * env.CAD_VALUE - quantity * env.CAD_VALUE * (discount / 100)) }
                  </span>
                </h3>
              </>
            ) : (
              <h3>
                Valor à pagar: <span>{ toCurrency(+quantity * env.CAD_VALUE) }</span>
              </h3>
            )}
          </ValueContainer>

          {/* {(['credit_card','debit_card'].includes(paymentMethod)) && (
            <InputsGrid className="animeLeft">
              <MaskedInput
                mask={maskCardNumber}
                placeholder="Número do cartão"
                guide={false}
                type="text"
                value={cardNumber}
                onChange={(event) => setCardNumber(event.target.value)}
                required
              />
              <input
                placeholder="Nome no cartão"
                type="text"
                value={cardHolderName}
                onChange={(event) => setCardHolderName(event.target.value)}
                required
              />
              <MaskedInput
                mask={maskCardExpirationDate}
                placeholder="Data de venc. (00/00)"
                guide={false}
                type="text"
                value={cardExpirationDate}
                onChange={(event) => setCardExpirationDate(event.target.value)}
                required
              />
              <MaskedInput
                mask={maskCardCvv}
                placeholder="CVV"
                guide={false}
                type="text"
                value={cardCvv}
                onChange={(event) => setCardCvv(event.target.value)}
                required
              />
            </InputsGrid>
          )} */}

          <hr className='hr'></hr>

          {loading ? (
            <button type="submit" disabled>
              Comprando
            </button>
          ) : (
            <button type="submit">Comprar</button>
          )}
        </Container>
      </Modal>
    </>
  );
}
